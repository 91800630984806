import {createMemoryHistory, createRouter, createWebHistory} from 'vue-router'
import startView from "../views/StartView.vue";
import questionsView from "../views/QuestionsView.vue";
import resultAView from "../views/ResultAView.vue";
import resultBView from "../views/ResultBView.vue";

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: '/',
      name: 'startView',
      component: startView
    },
    {
      path: '/questions',
      name: 'questionsView',
      component: questionsView
    },
    {
      path: '/resulta',
      name: 'resultAView',
      component: resultAView
    },
    {
      path: '/resultb',
      name: 'resultBView',
      component: resultBView
    }
  ]
});
export default router
