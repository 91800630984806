<script setup>

</script>

<template>
  <div id="app">
    <div class="mobile-view">
     <RouterView/>
    </div>
  </div>
</template>

<style scoped>
/* Базовые стили для имитации мобильного устройства */
.mobile-view {
  width: 428px; /* Примерная ширина мобильного устройства */
  height: auto; /* Примерная высота мобильного устройства */
  margin: auto; /* Центрирование */
  position: relative; /* Необходимо для позиционирования */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Добавление тени для эффекта выделения */
  overflow: hidden; /* Скрывает переполнение контента */

}

/* Заблюренный фон */
#app:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(206, 166, 166, 0.8); /* Белый фон с небольшой прозрачностью */
  backdrop-filter: blur(5px); /* Блюр фона */
  z-index: -1; /* Убедитесь, что фон находится под контентом */
}

@media screen and (max-width: 768px) {
  /* Стили для мобильных устройств, возможно, блюр и размеры не нужны */
  .mobile-view {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
  #app:before {
    display: none; /* Убираем блюр для мобильных устройств */
  }
}
</style>