import { defineStore } from "pinia";
import { ref } from "vue";
import questionNum1 from "../assets/images/question-1-5.svg"
import questionNum2 from "../assets/images/question-2-5.svg"
import questionNum3 from "../assets/images/question-3-5.svg"
import questionNum4 from "../assets/images/question-4-5.svg"
import questionNum5 from "../assets/images/question-5-5.svg"
import imgQuestions1 from "../assets/images/img-questions-1.webp"
import imgQuestions2 from "../assets/images/img-questions-2.webp"
import imgQuestions3 from "../assets/images/img-questions-3.webp"
import imgQuestions4 from "../assets/images/img-questions-4.webp"
import imgQuestions5 from "../assets/images/img-questions-5.webp"
import router from "../router/index.js";

export const useStore = defineStore('store', () => {
    const questions = ref([
        {
            id: '1',
            question: '1. Лучшая роль Марго Робби — это…',
            image: imgQuestions1,
            numberImage: questionNum1,
            additionalImage: '',
            answerA: 'А. Разумеется, «Волк с Уолл-стрит». А что есть другие варианты?',
            answerB: 'Б. Барби и только Барби!',
            resultA: false,
            resultB: false,
            show: true
        },
        {
            id: '2',
            question: '2. Идеальное утро начинается с…',
            image: imgQuestions2,
            numberImage: questionNum2,
            additionalImage: '',
            answerA: 'А. Американо (и покрепче!)',
            answerB: 'Б. Капучино на миндальном',
            resultA: false,
            resultB: false,
            show: false
        },
        {
            id: '3',
            question: '3. В детстве я всегда представляла себя…',
            image: imgQuestions3,
            numberImage: questionNum3,
            additionalImage: '',
            answerA: 'А. Принцессой',
            answerB: 'Б. Волшебницей с суперсилой',
            resultA: false,
            resultB: false,
            show: false
        },
        {
            id: '4',
            question: '4. Нежные воланы или соблазнительная шнуровка?',
            image: imgQuestions4,
            numberImage: questionNum4,
            additionalImage: '',
            answerA: 'А. Однозначно воланы',
            answerB: 'Б. Шнуровка!',
            resultA: false,
            resultB: false,
            show: false
        },
        {
            id: '5',
            question: '5. Если мне скажут, что нужно оставить лишь один образ в гардеробе — это будет…',
            image: imgQuestions5,
            numberImage: questionNum5,
            additionalImage: '',
            answerA: 'А. Платье в пол, украшенное рюшами',
            answerB: 'Б. Открытое боди и мини-юбка',
            resultA: false,
            resultB: false,
            show: false
        }
    ]);
    const resultA = 'Вы — самая настоящая femme fatale. Открытые плечи, пикантные вырезы и длина мини — кажется, ' +
        'что быть в центре внимания — ваше призвание. По крайней мере, сегодня у вас точно именно такой настрой. ' +
        'Вдохновляйтесь вещами из новой коллекции и составляйте луки, в которых вы гарантированно не останетесь ' +
        'незамеченной.'

    const resultB = 'Нежная и женственная — не имеет значения, сколько вам лет, в душе вы всегда остаетесь ' +
        'принцессой. Вы не можете пройти мимо оборок и рюшей — и, честно говоря, мы вас очень хорошо понимаем, ' +
        'потому что и сами являемся большими поклонниками вещей с приставкой girly'

    const filteredQuestion = () => {
        return questions.value.find(question => question.show);

    }
    const handleAnswer = (questionId, selectedOption) => {
        const questionIndex = questions.value.findIndex(q => q.id === questionId);
        if (questionIndex !== -1) {
            questions.value[questionIndex].resultA = selectedOption === 'A';
            questions.value[questionIndex].resultB = selectedOption === 'B';
            // Переключение на следующий вопрос, если не последний
            if (questionIndex + 1 < questions.value.length) {
                questions.value[questionIndex].show = false;
                questions.value[questionIndex + 1].show = true;
            }
            // Проверяем, является ли текущий вопрос последним
            if (questionIndex + 1 === questions.value.length) {
                calculateResultsAndNavigate(); // Вызов функции подсчета результатов и перенаправления
            }
        }
    };
    const calculateResultsAndNavigate = () => {
        const countA = questions.value.filter(q => q.resultA).length;
        const countB = questions.value.filter(q => q.resultB).length;

        if (countA > countB) {
            // Если ответов А больше, перенаправляем на resultAView
            router.push({ name: 'resultAView' });
        } else {
            // Иначе, если ответов Б больше или равное количество ответов А и Б, перенаправляем на resultBView
            router.push({ name: 'resultBView' });
        }
    };

    const redirectToSite = () => {
        window.location.href = 'https://embody.ru';

    }

    return { questions, resultA, resultB, filteredQuestion, handleAnswer, redirectToSite };
});
