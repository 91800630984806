<script setup>

</script>

<template>
  <div class="h-[9px] w-full bg-lineFooterPink"></div>
  <div class="relative h-[110px] w-full bg-footerPuffPink">
    <div class="w-full flex justify-center ">
      <div class="w-[320px] flex justify-between pl-6 pr-6 text-[#7A5350] text-[5px] mt-[13px] font-arial text-custom">
        <a href="https://embody.ru/collection/all">КАТАЛОГ</a>
        <a href="https://embody.ru/page/about-us">О НАС</a>
        <a href="https://embody.ru/page/delivery-and-return">ДОСТАВКА</a>
      </div>
    </div>
    <div class="w-full flex justify-center mt-6">
      <a href="https://www.instagram.com/embody.brand/">
        <img src="../assets/insta.svg" alt="insta"/>
      </a>
    </div>
  </div>
</template>

